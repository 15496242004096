<template>
  <div class="msubs-body">
    <div class="msubs-container">
      <nav class="msubs-sidebar">
        <div class="logo">
          <router-link :to="{ name: 'home' }">
            <img :src="msubs_logo" alt="m subs" />
          </router-link>
          <ul class="msubs-nav-menu">
            <li>
              <a href="javascript:void(0)">
                <font-awesome-icon class="mr-3" icon="user" size="xl">
                </font-awesome-icon>
                Hello,
                {{
                  userData.user_details.first_name ||
                  userData.user_details.username
                }}
              </a>
            </li>
            <li v-if="hasPermission('CAN_DO_QC')"
              class="msubs-nav-menu-item"
              :class="{ active: active_menu == 'qc-dashboard' }"
            >
              <router-link :to="{ name: 'qc-dashboard' }"
                ><font-awesome-icon class="mr-3" icon="table-columns" size="xl">
                </font-awesome-icon
                >QC Dashboard</router-link
              >
            </li>
            <li v-else-if="hasPermission('CAN_VIEW_JOBS')"
              class="msubs-nav-menu-item"
              :class="{ active: active_menu == 'dashboard' }"
            >
              <router-link :to="{ name: 'dashboard' }"
                ><font-awesome-icon class="mr-3" icon="table-columns" size="xl">
                </font-awesome-icon
                >Dashboard</router-link
              >
            </li>
            <li v-if="hasPermission('CAN_VIEW_ALL_REPORTS')"
              class="msubs-nav-menu-item"
              :class="{ active: active_menu == 'usage' }"
            >
              <router-link :to="{ name: 'usage' }"
                ><font-awesome-icon class="mr-3" icon="table-columns" size="xl">
                </font-awesome-icon
                >Usage</router-link
              >
            </li>
            <li v-else-if="hasPermission('CAN_VIEW_REPORTS')"
              class="msubs-nav-menu-item"
              :class="{ active: active_menu == 'usage' }"
            >
              <router-link :to="{ name: 'usage' }"
                ><font-awesome-icon class="mr-3" icon="table-columns" size="xl">
                </font-awesome-icon
                >Usage</router-link
              >
            </li>
            <li
              class="msubs-nav-menu-item"
              :class="{ active: active_menu == 'settings' }"
            >
              <router-link :to="{ name: 'profile' }"
                ><font-awesome-icon
                  class="mr-3"
                  icon="gear"
                  size="xl"
                ></font-awesome-icon
                >Settings</router-link
              >
            </li>
            <li class="msubs-nav-menu-item">
              <a href="javascript:void(0)" @click="logout()"
                ><font-awesome-icon
                  class="mr-3"
                  icon="power-off"
                  size="xl"
                ></font-awesome-icon
                >Logout</a
              >
            </li>
          </ul>
        </div>
        <div class="footer">
          <!-- <p>PRODUCT BY</p> -->
          <img :src="infoesearch_logo" alt="INFO eSEARCH" />
        </div>
      </nav>

      <router-view />

      <!-- <nav class="msubs-right-sidebar"></nav> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      msubs_logo: `${this.$api.host_url}/images/msubs-logo-4.png`,
      infoesearch_logo: `${this.$api.host_url}/images/productby-infoesearch.png`,
      userData: {},
      active_menu: "",
      menus: [
        {
          name: "Jobs",
          path: "/jobs",
          permission: "CAN_DO_ANYTHING",
        },
        {
          name: "Menu",
          permission: "CAN_DO_ANYTHING",
          submenu: [
            {
              name: "Jobs",
              path: "/jobs",
            },
          ],
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      this.setActiveMenu();
    },
  },
  created() {
    this.userData = $cookies.get("user");
  },
  mounted() {
    // NAVBAR
    this.setActiveMenu();
    $(".dropdown-menu a.dropdown-toggle").on("click", function () {
      if (!$(this).next().hasClass("show")) {
        $(this)
          .parents(".dropdown-menu")
          .first()
          .find(".show")
          .removeClass("show");
      }
      var $subMenu = $(this).next(".dropdown-menu");
      $subMenu.toggleClass("show");
      $(this)
        .parents("li.nav-item.dropdown.show")
        .on("hidden.bs.dropdown", function () {
          $(".dropdown-submenu .show").removeClass("show");
        });
      return false;
    });
  },
  methods: {
    setActiveMenu: function () {
      if (["dashboard", "create-job"].includes(this.$route.name)) {
        this.active_menu = "dashboard";
      } else if (["profile", "change-password"].includes(this.$route.name)) {
        this.active_menu = "settings";
      } else {
        this.active_menu = "";
      }
    },
    logout: function () {
      if ($cookies.isKey("user")) {
        $cookies.remove("user");
      }
      if (sessionStorage.getItem("user")) {
        sessionStorage.removeItem("user");
      }
      if ($cookies.isKey("user.customer_slug")) {
        $cookies.remove("user.customer_slug");
      }
      if (sessionStorage.getItem("user.customer_slug")) {
        sessionStorage.removeItem("user.customer_slug");
      }
      this.$router.push({ name: "login" });
    },
  },
};
</script>
<style>
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.msubs-body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  background-color: #f5f5f5;
  letter-spacing: 1px;
  font-weight: 400;
}

.msubs-container {
  display: flex;
  /* height: 100vh; */
}

/* Sidebar */
.msubs-sidebar {
  width: 300px;
  background-color: #010a35;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  height: 100vh;
}

.msubs-sidebar .logo img {
  width: 200px;
  /* max-width: 120px; */
  margin-bottom: 50px;
}

.msubs-sidebar .msubs-nav-menu {
  list-style: none;
  padding: 0;
  padding-left: 5px;
}

.msubs-sidebar .msubs-nav-menu li:first-child a {
  cursor: default;
}

.msubs-sidebar .msubs-nav-menu li a {
  color: #9e9e9e;
  text-decoration: none;
  font-size: 1.1em;
  display: block;
  padding: 15px;
  border-radius: 5px;
}

.msubs-sidebar .msubs-nav-menu .msubs-nav-menu-item a:hover {
  color: #fff;
}

.msubs-sidebar .msubs-nav-menu li.active a {
  color: #fff;
  font-weight: bold;
  /* background-color: #1f2e53; */
}

.msubs-sidebar .footer {
  text-align: center;
}

.msubs-sidebar .footer p {
  font-size: 0.8em;
  margin: 0;
  margin-bottom: 10px;
}

.msubs-sidebar .footer img {
  width: 100%;
  /* max-width: 100px; */
}

.msubs-right-sidebar {
  width: 30px;
  background-color: #010a35;
}

.msubs-content {
  flex: 1;
  /* padding: 20px; */
}

.msubs-body-container {
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;
  /*overflow: scroll;*/
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.msubs-header {
  /* padding-top: 10px; */
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #010a35;
}

.msubs-header h2 {
  font-weight: bold;
  color: #102384;
  font-size: 1.5em;
  margin: 0;
}

.msubs-header h2 .msubs-breadcrumb {
  color: #6a6a6a;
  font-weight: 200;
  font-size: 0.7em;
  margin-left: 0;
}

.msubs-disable {
  opacity: 0.5;
  pointer-events: none;
}

.readonly-input-group {
  opacity: 0.8;
  background: #f0eded;
}

.readonly-input-group input {
  background: #f0eded;
}

/* FORM CSS */

.msubs-form {
  display: flex;
  flex-direction: column;
  max-width: 50%;
}

.msubs-form-group {
  border: 1px solid #ddd;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 15px;
  display: flex;
}

.msubs-form-group img {
  width: 50px;
  height: 63px;
  margin-right: 1rem;
}

.msubs-form-group .input-div {
  height: 100%;
  width: 100%;
}

.msubs-form-group label {
  color: #7a7a7a;
  font-size: 0.8em;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: block;
}

.msubs-form-group input[value] {
  font-size: 18px !important;
}

.msubs-form-group .b-form-select {
  font-size: 19px !important;
  height: 30%;
}

.msubs-form-group input[type="text"],
.msubs-form-group input[type="password"],
.msubs-form-group select {
  width: 100%;
  padding: 0;
  font-size: 1.1em;
  letter-spacing: 1px;
  /* color: #102384; */
  border: none;
  outline: none;
}

.msubs-form-group .b-form-checkbox-group,
.msubs-form-group .b-form-radio-group {
  border: none;
  outline: none;
  height: auto;
  position: relative;
  overflow-x: hidden;
  padding: 0;
  padding-top: 5px;
  margin-bottom: 2px;
}

.msubs-form-group .b-form-checkbox-group .custom-checkbox label,
.msubs-form-group .b-form-radio-group label {
  /* color: #102384; */
  color: black;
  text-transform: none;
  font-size: 1em;
  margin-bottom: 7px !important;
}

.msubs-form-group .b-form-radio-group label {
  /* color: #102384; */
  color: black;
  text-transform: none;
  font-size: 1em;
}

.msubs-form-group .upload-field {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.msubs-form-group .upload-field input[type="text"] {
  cursor: pointer;
  flex: 1;
  margin-right: 10px;
  padding-right: 45px;
}

.form-actions {
  display: flex;
}

.form-actions .msubs-btn {
  margin-top: 10px;
  margin-right: 15px;
  letter-spacing: 1px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 25px;
  border: none;
  background: rgb(66, 192, 212);
  background: linear-gradient(
    90deg,
    rgba(66, 192, 212, 1) 21%,
    rgb(63, 212, 194) 56%
  );
}

.form-actions .msubs-btn:hover {
  background: rgb(66, 163, 212);
  background: linear-gradient(
    90deg,
    rgba(66, 163, 212, 1) 21%,
    rgba(45, 200, 181, 1) 56%
  );
}

.form-actions .cancel {
  margin-top: 10px;
  letter-spacing: 1px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 25px;
  color: black;
  background: white;
}

/* Pagination */
.pagination .page-item span {
  background: linear-gradient(
    90deg,
    rgba(66, 192, 212, 1) 21%,
    rgb(63, 212, 194) 56%
  ) !important;
  color: White !important;
  font-weight: 600;
}

.pagination .page-item span:hover {
  background: linear-gradient(
    90deg,
    rgba(66, 163, 212, 1) 21%,
    rgba(45, 200, 181, 1) 56%
  ) !important;
}
</style>
