import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCookies from "vue-cookies";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import BootstrapVue from "bootstrap-vue";
import jQuery from "jquery";
import { ValidationObserver, ValidationProvider, extend, localize } from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import { min, required, email, max } from "vee-validate/dist/rules";
import VueProgressBar from 'vue-progressbar';
import globalVariables from './assets/js/global.js';
import methods from './mixins.js';
import apiEndpoints from './assets/js/apiEndpoints.js';
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faUserSecret,
    faHome,
    faEllipsisV,
    faBars,
    faCartPlus,
    faUsers,
    faCaretDown,
    faCaretUp,
    faFile,
    faPen,
    faTrashAlt,
    faPlus,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faSpinner,
    faTasks,
    faUser,
    faEye,
    faAngleDown,
    faTable,
    faPlayCircle,
    faThLarge,
    faCheck,
    faCaretLeft,
    faTimes,
    faRedo,
    faReply,
    faChevronDown,
    faArrowUp,
    faCog,
    faUserCircle,
    faUndo,
    faArrowRight,
    faPlusCircle,
    faFilePdf,
    faFileAlt,
    faFileExcel,
    faDownload,
    faTableColumns,
    faPowerOff
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
    faDownload,
    faUserSecret,
    faHome,
    faEllipsisV,
    faBars,
    faCartPlus,
    faUsers,
    faCaretDown,
    faCaretUp,
    faFile,
    faPen,
    faTrashAlt,
    faPlus,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faSpinner,
    faTasks,
    faUser,
    faEye,
    faAngleDown,
    faTable,
    faPlayCircle,
    faThLarge,
    faCheck,
    faCaretLeft,
    faTimes,
    faRedo,
    faReply,
    faChevronDown,
    faArrowUp,
    faCog,
    faUserCircle,
    faUndo,
    faArrowRight,
    faPlusCircle,
    faFilePdf,
    faFileAlt,
    faFileExcel,
    faTableColumns,
    faPowerOff
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(VueAxios, axios, VueCookies);
Vue.use(BootstrapVue);

const $ = jQuery;
window.$ = $;

// Progress bar customization settings
const options = {
    color: '#007bff',
    failedColor: '#ff0000',
    thickness: '3px'
        // height: '2px',
        // transition: {
        //     speed: '0.2s',
        //     opacity: '0.6s',
        //     termination: 300
        // }
};
Vue.use(VueProgressBar, options);

// Form Validation Rules Set
extend("required", required);
extend("min", min);
extend("email", email);
extend("max", max);
// Install messages for Form Validation Rules
localize({
    en
});
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);


Vue.config.productionTip = false;

// api endpoints and headers
Vue.prototype.$api = apiEndpoints;
Vue.prototype.$apiHeaders = globalVariables.headers;
Vue.prototype.$applicationTitle = globalVariables.application_title;

// page not found default settings
const errorMessage = {
    code: [4, 0, 1],
    msg: ""
};

// include global function with mixins
Vue.mixin({ methods });
Vue.mixin({
    methods: {
        hasPermission: function(permission) {
            const user = this.getUserData();
            const permit = user.user_details.permissions.filter(
                item => (item == permission) || (item == "CAN_DO_ANYTHING")
            );
            if (permit.length > 0) {
                return true;
            } else {
                errorMessage.msg =
                    "Access Denied, Please contact Admin for more details.";
                return false;
            }
        },
        hasAnyPermission: function(permissions) {
            const user = this.getUserData();
            let permission_count = 0;
            if(Array.isArray(permissions)) {
                permissions.forEach(permission => {
                    user.user_details.permissions.filter(function(item){
                        if((permission && (item.includes(permission))  || (item == "CAN_DO_ANYTHING"))){
                            permission_count++;
                        } 
                    });
                        
                });
            }
            if (permission_count > 0) {
                return true;
            } else {
                errorMessage.msg =
                    "Access Denied, Please contact Admin for more details.";
                return false;
            }
        },
        hasAllPermission: function(permissions) {
            const user = this.getUserData();
            let permission_count = 0;
            if(Array.isArray(permissions)) {
                permissions.forEach(permission => {
                    user.user_details.permissions.filter(function(item){
                        if((permission && (item.includes(permission))  || (item == "CAN_DO_ANYTHING"))){
                            permission_count++;
                        } 
                    });
                        
                });
            }
            if(permissions.length == permission_count){
                return true;
            } else {       
                errorMessage.msg =
                    "Access Denied, Please contact Admin for more details.";
                return false;
            }   
        },
        getPageMessage: function(message) {
            errorMessage.msg = message;
            return true;
        },
        getErrorMessage: function() {
            return errorMessage;
        },
        getRouteParams: function(param = null) {
            if (param === null){
                return this.$route.params;
            } else if (Object.prototype.hasOwnProperty.call(this.$route.params,param)){
                return this.$route.params[param];
            } else {
                return null;
            }
        },
        getRouteQueryParams: function(query = null) {
            if (query === null){
                return this.$route.query;
            } else if (Object.prototype.hasOwnProperty.call(this.$route.query,query)){
                return this.$route.query[query];
            } else {
                return null;
            }
        },
        alert: function(type, message, seconds = null, dismissable = null) {
            this.$emit('onAlert', {
                type: type,
                message: message,
                seconds: seconds,
                dismissable: dismissable
            });
        },
        getFileName(url)
        {
            let string =url.substring(url.lastIndexOf('/')+1);
            return string;
        }
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");