import api from "../../../public/config.js";

// split host url and port from domain if localhost exists
const localhostUrl = location.host.split(':');
const apiBaseUrl = (api.apiBaseUrl.includes("localhost")) ? api.apiBaseUrl.replace("localhost", localhostUrl[0]) : api.apiBaseUrl;
const msubsBaseUrl = apiBaseUrl + 'api/';


const apiEndpoints = {
    host_url: apiBaseUrl,
    login: msubsBaseUrl + "login",
    logout: msubsBaseUrl + "logout",
    update_profile: msubsBaseUrl + "users/update-profile",
    change_password: msubsBaseUrl + "users/change-password",
    get_jobs: msubsBaseUrl + "jobs/",
    get_usage_reports: msubsBaseUrl + "reports/",
    download_usage_reports: msubsBaseUrl + "reports/download/",
    get_customer_list: msubsBaseUrl + "reports/get-customers",
    view_job: msubsBaseUrl + "jobs/view",
    get_transcription_languages: msubsBaseUrl + "transcription-languages/",
    get_translation_languages: msubsBaseUrl + "translation-languages/",
    create_job: msubsBaseUrl + "jobs/create",
    create_qc_output: msubsBaseUrl + "jobs/create-qc-output",
    delete_qc_output_file: msubsBaseUrl + "jobs/delete-qc-output-file",
    complete_job:msubsBaseUrl + "jobs/complete-job"
}

export default apiEndpoints;
