module.exports = {
    getUserData: function() {
        if ($cookies.isKey("user")) {
            return $cookies.get("user");
        }
    },
    getAuthHeaders: function() {
        const user = this.getUserData();
        // const customer = $cookies.get("user.customer_slug");
        if (user != (null || undefined)) {
            this.$apiHeaders["headers"]["Authorization"] = "Bearer " + user.access_token;
            // this.$apiHeaders["headers"]["X-Customer"] = customer;
        }
        return this.$apiHeaders;
    },
    startProgressBar: function() {
        return this.$Progress.start();
    },
    updateProgressBar: function(status) {
        if (status) {
            return this.$Progress.finish();
        } else {
            return this.$Progress.fail();
        }
    },
    deleteEmptyKeys: function(data) {
        var query = {};
        for (var key in data) {
            (data[key] !== '') ? query[key] = data[key]: '';
        }
        return query;
    },
    getApiUrlByProcess: function(process, api_end_point) {
        return this.$api[process][api_end_point];
    },
}